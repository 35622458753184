import Cookies from 'js-cookie'

type GeneralConsentCookie = {
  adrollConsent: boolean
  facebookConsent: boolean
  googleConsent: boolean
  amplitudeConsent: boolean
  marketing: boolean
  analytics: boolean
}

const GENERAL_CONSENT_COOKIE_NAME = 'qcc'
const COOKIE_EXPIRATION_TIME = 365

const getCookieDomain = () => {
  const COOKIE_DOMAIN: string = '.' + window.location.host.split('.').slice(-2).join('.')

  const shouldSetCookieDomain =
    !COOKIE_DOMAIN.includes('localhost') &&
    !COOKIE_DOMAIN.includes('vercel') &&
    !window.location.host.includes('preview.qasa.com') &&
    !window.location.host.includes('local.qasa.blocket')

  if (shouldSetCookieDomain) {
    return COOKIE_DOMAIN
  }

  return undefined
}

export enum CookieNames {
  NEW_LISTING_DRAFT = 'NEW_LISTING_DRAFT',
  SELECTED_LANGUAGE = 'Language',
  BOOKING_MESSAGE = 'BOOKING_MESSAGE',
  ORIGIN = 'ORIGIN',
}

const getConsentCookie = () => {
  const existingCookie = Cookies.get(GENERAL_CONSENT_COOKIE_NAME)
  const existingData: Partial<GeneralConsentCookie> = existingCookie ? JSON.parse(existingCookie) : {}

  return existingData
}

const setConsentCookie = (properties: Partial<GeneralConsentCookie>) => {
  const existingCookie = Cookies.get(GENERAL_CONSENT_COOKIE_NAME)
  const existingData: GeneralConsentCookie = existingCookie ? JSON.parse(existingCookie) : {}

  const newData = { ...existingData, ...properties }

  const cookieDomain = getCookieDomain()

  Cookies.set(GENERAL_CONSENT_COOKIE_NAME, JSON.stringify(newData), {
    expires: COOKIE_EXPIRATION_TIME,
    ...(cookieDomain && {
      domain: cookieDomain,
    }),
  })
}

const hasMadeConsentChoice = () => {
  return getConsentCookie().analytics !== undefined
}
// NOTE: Not used for BBQ or QaFi yet - Sahin 2023-07-07
export const ConsentCookie = {
  get: getConsentCookie,
  set: setConsentCookie,
  hasMadeChoice: hasMadeConsentChoice,
}
